<template>
  <div class="home" style="overflow: initial;">
    <div class="tabs">
      <ul>
        <li :class="{ 'is-active': tab == 'style' }" @click="changeTab('style')">
          <a>Tema</a>
        </li>
        <li :class="{ 'is-active': tab == 'style-web-player' }" @click="changeTab('style-web-player')">
          <a>Tema Web-Player</a>
        </li>
        <li :class="{ 'is-active': tab == 'stations' }" @click="changeTab('stations')">
          <a>Emisoras</a>
        </li>
        <li :class="{ 'is-active': tab == 'menu' }" @click="changeTab('menu')">
          <a>Menu</a>
        </li>
         <li :class="{ 'is-active': tab == 'bannerTop' }" @click="changeTab('bannerTop')">
          <a>Banner Superior</a>
        </li>
         <li :class="{ 'is-active': tab == 'bannerBottom' }" @click="changeTab('bannerBottom')">
          <a>Banner Inferior</a>
        <li :class="{ 'is-active': tab == 'logo' }" @click="changeTab('logo')">
          <a>Logo</a>
        </li>
           <li :class="{ 'is-active': tab == 'notifications' }" @click="changeTab('notifications')">
          <a>Notificaciones móviles</a>
        </li>
           <li :class="{ 'is-active': tab == '' }" @click="logout()">
          <a>Logout</a>
        </li>
      </ul>
    </div>
    <!-- Muestra el formulario de estilos de la aplicacion  -->
    <div v-if="tab == 'style'">
      <div class="columns is-centered">
        <div class="field is-half">
          <label for class="label">Imagen de fondo <br>
          <small>
             (420 x 750)
          </small>
          </label>
           <input type="file"
       ref="file"
       :name="uploadFieldName"
       @change=uploadImage
       style="display:none">
            <a class="btn" @click="launchFilePicker">
                <img style="opacity: 200px; height: 354px;" v-if="loading" :src="'https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif'" />

                 <img style="width: 200px; height: 354px;" v-if="!loading" :src="imgDataUrl || 'https://bulma.io/images/placeholders/256x256.png'" />
            </a>
        </div>
      </div>
      <div class="field">
        <label for="tema" class="label">Selecciona un tema</label>
        <div class="select width-full" id="tema">
          <select  v-model="form.theme" @change="update">
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="tab == 'style-web-player'">
       <div class="columns is-centered">
        <div class="field is-half">
          <label for class="label">Imagen de fondo</label>
          <a class="btn" @click="toggleShow2">
            <my-upload
              field="img"
              @crop-success="cropSuccess2"
              @crop-upload-success="cropUploadSuccess2"
              @crop-upload-fail="cropUploadFail2"
              v-model="show2"
              :width="200"
              :height="200"
              url="https://httpbin.org/post"
              lang-type="es-MX"
              :params="params"
              :headers="headers"
              img-format="png"
            ></my-upload>
            <img style="width: 600px; height: 200px;" :src="imgDataUrl2 || 'https://bulma.io/images/placeholders/256x256.png'" />
          </a>
        </div>
      </div>
      <div class="field">
        <label for="tema" class="label">Selecciona un tema</label>
        <div class="select width-full" id="tema">
          <select  v-model="form.theme" @change="update">
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select>
        </div>
      </div>
    </div>
    <!-- Muestra la lista de las emisoras -->
    <div v-if="tab == 'stations'">
      <crud></crud>
    </div>

  <!-- Muestra la configuracion de los banners-->
    <div v-if="tab == 'bannerTop'">
      <banners></banners>
    </div>
   <div v-if="tab == 'bannerBottom'">
      <bannerBottom></bannerBottom>
    </div>
    <!-- Muestra el menu -->
    <div v-if="tab == 'menu'">
      <crudMenu></crudMenu>
    </div>
     <div v-if="tab == 'logo'" class="notifications">
        <logo></logo>
    </div>

    <!-- Muestra el menu -->
    <div v-if="tab == 'notifications'" class="notifications">
       <notification></notification>
      <!-- <a href="https://app.onesignal.com/apps/677985f3-49bf-47f6-8965-576cc4a3346f" target="_blank">
        <img height="190" width="450" src="https://www.getbeamer.com/wp-content/uploads/2018/01/onesignal-logo.png"  />
      </a> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import myUpload from "vue-image-crop-upload";
import  {db, storageRef} from "../services/firebase-init";
import crud from "../components/shared/crud";
import crudMenu from "../components/shared/crudMenu";
import notification from "../components/shared/notifications";

import banners from "../components/banners";
import bannerBottom from "../components/bannersBottom";
import logo from "../components/logo";
export default {
  name: "home",

  components: {
    HelloWorld,
    myUpload,
    crud,
    crudMenu,
    notification,
    banners,
    bannerBottom,
    logo
  },
 
  data() {
    return {
      tab: "style",
      show: false,
      loading:false,
      show2: false,
      keyTheme: '',
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      },
      imgDataUrl: "", // the datebase64 url of created image
      imgDataUrl2: "", // the datebase64 url of created image
      form: { },
      data: [
        {
          id: 88,
          first_name: "Wilhelm",
          last_name: "McCahey",
          email: "wmccahey2f@microsoft.com",
          credit_card: "maestro"
        }
      ],
      fields: {
        id: {
          type: "ID",
          title: "ID",
          sortable: true
        },
        first_name: {
          type: "Text",
          title: "First Name",
          sortable: true
        },
        last_name: {
          type: "Text",
          title: "Last Name",
          sortable: true
        }, 
        credit_card: {
          type: "Select",
          title: "Credit Card Type",
          filterable: true,
          componentProps: {
            options: {
              maestro: "Maestro",
              master_card: "Master Card",
              visa: "Visa"
            }
          }
        },
        email: {
          type: "Email",
          title: "Email"
        }
      }
    };
  },
   created() {
       let form = db.ref("/form/styles");
        form.on("value", snapshop => {
            this.form = snapshop.val();
            this.keyTheme = this.form['theme'] || 'light';
            this.imgDataUrl = this.form[this.keyTheme]['backgroundImageHome']
             this.imgDataUrl2 = this.form[this.keyTheme]['backgroundImageHomePlayer']
        });
  },
  methods: {
    previewFiles() {
      this.files = this.$refs.myFiles.files;
    },
    changeTab(tab) {
      this.tab = tab;
    },
    logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    this.$router.push('login') 
    },
    update(){
      this.save()
    },
    toggleShow() {
      this.show = !this.show;
    },
    toggleShow2() {
      this.show2 = !this.show2;
      console.log('-.-.-.-.- ',this.show2)
    },
    launchFilePicker(){
        this.$refs.file.click();
      },
     uploadImage(e){
       this.loading = true
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    console.log(this.previewImage);

                    var re = storageRef.child(String(Date.now()));
      re.putString(this.previewImage, "data_url").then(() => {
        re.getDownloadURL().then(downloadURL => {
                this.form[this.keyTheme]['backgroundImageHome'] = downloadURL;

            this.loading = false
                 this.imgDataUrl = this.previewImage;
           this.save()
          
      //    this.$refs.uploader.setStep(2)
        });
        // console.log("-------- crop success --------", imgDataUrl);
      });

                };
            },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
       var re = storageRef.child(String(Date.now()))
      re.putString(imgDataUrl, 'data_url').then(()=> {
        re.getDownloadURL().then((downloadURL) => {
           this.form[this.keyTheme]['backgroundImageHome'] = downloadURL;
           this.save()
        });
      });
      this.imgDataUrl = imgDataUrl;
    },
     cropSuccess2(imgDataUrl, field) {
       var re = storageRef.child(String(Date.now()))
      re.putString(imgDataUrl, 'data_url').then(()=> {
        re.getDownloadURL().then((downloadURL) => {
           this.form[this.keyTheme]['backgroundImageHomePlayer'] = downloadURL;
           this.save()
        });
      });
      this.imgDataUrl2 = imgDataUrl;
    },
    save(){
       let ref = db.ref("/form/styles")
       console.log('dkjdjs ', this.form)
      if(!this.form && !this.form['id']){
         ref.child(id).set(this.form);
      }else{
        ref.update(this.form);
      }
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
     cropUploadSuccess2(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    cropUploadFail2(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    }
  }
};
</script>
<style>width
.width-full {
  width: 256px;
}
</style>