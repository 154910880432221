/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import routers from './router'
import VueCarousel from 'vue-carousel';
import draggable from 'vuedraggable'

new Vue({
  router: routers,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueCarousel)
Vue.component('draggable',draggable)
Vue.config.productionTip = false