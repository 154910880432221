<!--eslint-disable-->
<template>
  <div class="crud">
    <div class="column is-one-quarter level-left">
      <a class="button" @click="change()">
        <i class="fas fa-plus"></i>
      </a>
    </div>
    <div v-if="view == 'list'">
      <div class="columns is-centered">
        <div class="table-container is-centered">
          <table class="table">
            <tr>
              <th>Icono</th>
              <th>Nombre</th>
              <th>Acción</th>
            </tr>
            <draggable
              v-model="list"
              :list="list"
              group="people"
              @start="drag=true"
              @end="drag=false"
              tag="tbody"
            >
              <tr v-for="item in list" :key="item.id">
                <td>
                  <img :src="item.image" alt height="70" width="70" />
                </td>
                <td>{{item.name}}</td>
                <td>
                  <a class="button" style="margin-right: 15px" @click="setForm(item.id)">Editar</a>
                  <a class="button is-danger" @click="remove(item.id)">Eliminar</a>
                </td>
              </tr>
            </draggable>
            <!-- Your table content -->
          </table>
        </div>
      </div>
    </div>

    <!-- Este el formulario para agregar o editar elementos -->
    <div v-if="view == 'form'">
      <div class="column is-one-quarter level-left">
        <a style="font-size: 24px; color: black" @click="setList"></a>
      </div>
      <div style=" width:40vw; margin:0 auto;" class="content-form">
        <div class="columns is-centered">
          <div class="field is-half">
            <label for class="label">Imagen de fondo</label>
            <a class="btn" @click="toggleShow">
              <my-upload
                ref="uploader"
                field="img"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="show"
                :width="100"
                :height="100"
                url="https://httpbin.org/post"
                lang-type="es-MX"
                :params="params"
                :headers="headers"
                img-format="png"
              ></my-upload>
              <img
                height="150"
                width="150"
                :src="imgDataUrl || 'https://bulma.io/images/placeholders/256x256.png'"
              />
            </a>
          </div>
        </div>
        <div class="field">
          <label for="tema" class="label">Nombre Emisora</label>
          <input v-model="form.name" class="input width-full" type="text" placeholder="Nombre" />
          <label for="tema" class="label" style="margin-top: 15px">Url a Redireccionar</label>
          <input v-model="form.urlMenu" class="input width-full" type="text" placeholder="Url" />
        </div>
      </div>

      <!-- <div class="field">
        <label for="tema" class="label">Url Emisora</label>
        <input v-model="form.icon" class="input width-full" type="text" placeholder="Url" />
      </div>-->
      <div class="field" style="margin-top: 20px">
        <a v-if="!edit" class="button width-full" @click="add">Guardar</a>
        <a v-if="edit" class="button width-full" @click="update(form.id)">Guardar</a>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import HelloWorld from "@/components/HelloWorld.vue";
import myUpload from "vue-image-crop-upload";
import { setTimeout } from "timers";
import { db, storageRef } from "../../services/firebase-init";

export default {
  name: "crud",
  components: {
    HelloWorld,
    myUpload
  },
  data() {
    return {
      imgDataUrl: "", // the datebase64 url of created image
      view: "list",
      edit: false,
      show: false,
      list: [],
      form: {},
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      }
    };
  },
  created() {
    let form = db.ref("/form/menu");
    form.on("value", snapshop => {
      let obj = snapshop.val();
      let keys = Object.keys(obj);
      this.list = keys.map(key => {
        obj[key]["id"] = key;
        return obj[key];
      });
      console.log("_____ ", this.list);
    });
  },
  updated() {
    // Fired every second, should always be true

    let obj = {};
    this.list.forEach((element, index) => {
      obj[(index + 1).toString()] = element;
    });
    let ref = db.ref("/form/menu");

    ref.set(obj);
    console.log("^^^^", obj);
  },
  methods: {
    change() {
      if (this.view == "list") {
        this.view = "form";
      } else {
        this.view = "list";
      }

    this.imgDataUrl = "";
    this.form = {};

    },
    setForm(id) {
      this.edit = id;
      this.view = "form";
      console.log("----- ", id);

      if (!this.edit) {
        this.imgDataUrl = "";
        this.form = {};
        return;
      }
      let ref = db.ref("/form/menu");
      ref.child(id).on("value", snapshot => {
        this.form = snapshot.val();
        this.form["id"] = id;
        this.imgDataUrl = this.form["image"];
      });
    },
    setList() {
      this.view = "list";
    },
    add() {
      let ref = db.ref("/form/menu");
      let stations = ref.push();
      stations.set(this.form);
      this.setList();
    },
    update(id) {
      let ref = db.ref("/form/menu");
      ref.child(id).update(this.form);
      this.setList();
    },
    remove(id) {
      if (!confirm("Realmente Desea eliminar el elemento")) return;
      let ref = db.ref("/form/menu");
      ref.child(id).remove();
    },
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      var re = storageRef.child(String(Date.now()));
      re.putString(imgDataUrl, "data_url").then(() => {
        re.getDownloadURL().then(downloadURL => {
          this.form["image"] = downloadURL;
        });
        // console.log("-------- crop success --------", imgDataUrl);
        this.$refs.uploader.setStep(2);
      });
      this.imgDataUrl = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    base64toBlob(b64Data, sliceSize) {
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: "image/jpeg" });
      return blob;
    }
  }
};
</script>
<style scoped>
.content-form {
  width: 50vw;
}
</style>