<!--eslint-disable-->
<template>
  <div class="crud">
    <div v-if="view == 'list'">
      <div class="columns is-centered banner-content">
        <div class="content">
          <div class="banner top">
            <Carousel :per-page="1" :mouse-drag="true" :paginationEnabled="true">
              <slide v-for="(slide, index) in list" :key="index">
                <img class="img-slide" :src="slide.image" alt />
              </slide>
            </Carousel>
           
          </div>
        </div>
      </div>

      <!--  -->
      <div class="column is-one-quarter level-left">
      </div>
      <div class="columns is-centered">
        <div class="table-container is-centered">
          <table class="table">
            <tr>
              <th>Icono</th>
              <th>Acción</th>
            </tr>
            <draggable v-model="list"   :list="list" group="people" @start="drag=true" @end="drag=false" :move="checkMove"  tag="tbody">
            <tr v-for="item in list" :key="item.id" >
              <td>
                <img :src="item['image']" alt height="70" width="70" />
              </td>
              <td>
                <a class="button" style="margin-right: 15px" @click="setForm(item.id)">Editar</a>
              </td>
            </tr>
            </draggable>
            <!-- Your table content -->
          </table>
        </div>
      </div>
    </div>

    <!-- Este el formulario para agregar o editar elementos -->
    <div v-if="view == 'form'">
      <div class="column is-one-quarter level-left">
        <a style="font-size: 24px; color: black" @click="setList">
          <i class="fas fa-arrow-left"></i>
        </a>
      </div>
      <div style=" width:40vw; margin:0 auto;" class="content-form">
        <div class="columns is-centered">
          <div class="field is-half">
            <label for class="label">Logo<br>
               <small>
                (830 x 830)
              </small>
            </label>
            <a class="btn" @click="toggleShow">
              <my-upload
                field="img"
                 ref="uploader"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="show"
                :width="512"
                :height="512"
                url="https://httpbin.org/post"
                lang-type="es-MX"
                :params="params"
                :headers="headers"
                img-format="png"
              ></my-upload>
              <img
                height="1300"
                width="400"
                :src="imgDataUrl || 'https://via.placeholder.com/830X830'"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="field" style="margin-top: 20px">
        <a v-if="edit" class="button width-full" @click="update(form.id)">Guardar</a>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import HelloWorld from "@/components/HelloWorld.vue";
import myUpload from "vue-image-crop-upload";
import { setTimeout } from "timers";
import { db, storageRef } from "../services/firebase-init";
import { Carousel, Slide } from "vue-carousel";



export default {
  name: "bannerBottom",
  components: {
    HelloWorld,
    myUpload,
    Carousel,
    Slide
  },
  data() {
    return {
      imgDataUrl: "", // the datebase64 url of created image
      view: "list",
      edit: false,
      show: false,
      list: [],
      url: '',
      listCopy:[],
      form: {},
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      }
    };
  },
  async created() {
  let form = db.ref("/form/styles/dark/images/logo");
  let formli = db.ref("/form/styles/light/images/logo");
  const snapshot1 = await form.once('value');
  const snapshot2 = await formli.once("value");
  let result1 = { id: "logodark", image: snapshot1.val() };
  let result2 = { id: "logo", image: snapshot2.val() };
  this.list = [result1,result2];
  },
  updated() {
    // Fired every second, should always be true
  },
  methods: {
    setForm(id) {
      this.edit = id;
      this.view = "form";
      console.log(this.form, " .----- ", id);
      if (!this.edit) {
        this.imgDataUrl = "";
        this.form = {};
        return;
      }
      if(id == "logodark") {
        let ref = db.ref("/form/styles/dark/images/logo");
        ref.on("value", snapshot => {
          let image = snapshot.val();
          console.log("````", this.form);
        });
      } else {
        let ref = db.ref("/form/styles/light/images/logo");
        ref.on("value", snapshot => {
          let image = snapshot.val();
          console.log("````", this.form);
        });
      }
    },
    checkMove(){
    
    },
    setList() {
      this.view = "list";
    },
    update(id) {
      let ref = db.ref("/form/styles/dark/images");
      var obj = {};
      obj["logo"] = this.form.image;
      ref.update(obj);
      this.setList();
    },
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      var re = storageRef.child(String(Date.now()));
      re.putString(imgDataUrl, "data_url").then(() => {
        re.getDownloadURL().then(downloadURL => {
          this.form["image"] = downloadURL;
          this.$refs.uploader.setStep(2)
        });
        // console.log("-------- crop success --------", imgDataUrl);
      });
      this.imgDataUrl = imgDataUrl;
      
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
         this.$refs.uploader.setStep(2)
    },
    base64toBlob(b64Data, sliceSize) {
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: "image/jpeg" });
      return blob;
    }
  }
};
</script>
<style scoped>
.content-form {
  width: 50vw;
}
.img-slide{
  max-width: 25%;
}

</style>