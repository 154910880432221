<!--eslint-disable-->
<template>
  <div class="crud">
    <div v-if="view == 'list'">
      <div class="columns is-centered banner-content">
        <div class="content">
          <div class="banner top">
            <Carousel :per-page="1" :mouse-drag="true" :paginationEnabled="true">
              <slide v-for="(slide, index) in list" :key="index">
                <img class="img-slide" :src="slide.image" alt />
              </slide>
            </Carousel>
           
          </div>
        </div>
      </div>

      <!--  -->
      <div class="column is-one-quarter level-left">
        <a class="button" @click="setForm(null)">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <div class="columns is-centered">
        <div class="table-container is-centered">
          <table class="table">
            <tr>
              <th>Imagen</th>
              <th>Acción</th>
            </tr>
            <draggable v-model="list"   :list="list" group="people" @start="drag=true" @end="drag=false" :move="checkMove"  tag="tbody">
            <tr v-for="item in list" :key="item.id" >
              <td>
                <img :src="item['image']['image']" alt height="70" width="70" />
              </td>
              <td>
                <a class="button" style="margin-right: 15px" @click="setForm(item.id)">Editar</a>
                <a class="button is-danger" @click="remove(item.id)">Eliminar</a>
              </td>
            </tr>
            </draggable>
            <!-- Your table content -->
          </table>
        </div>
      </div>
    </div>

    <!-- Este el formulario para agregar o editar elementos -->
    <div v-if="view == 'form'">
      <div class="column is-one-quarter level-left">
        <a style="font-size: 24px; color: black" @click="setList">
          <i class="fas fa-arrow-left"></i>
        </a>
      </div>
      <div style=" width:40vw; margin:0 auto;" class="content-form">
        <div class="columns is-centered">
          <div class="field is-half">
            <label for class="label">Banner <br>
              <small>
                (420 x 100)
              </small>
              </label>
                <input type="file"
       ref="file"
       :name="uploadFieldName"
       @change=uploadImage
       style="display:none">
            <a class="btn" @click="launchFilePicker">
           
               <img style="width: 200px; height: 200px;"  v-if="loading"  :src="'https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif'" />

              <img
               v-if="!loading" 
                :src="imgDataUrl || 'https://via.placeholder.com/420x100'" 
              />
            </a>
          </div>
           
        </div>
         <div class="columns is-centered">
        <div class="field">
              <label for="tema" class="label">Url Emisora</label>
              <input v-model="form['url']" class="input width-full" type="text" placeholder="Url" />
          </div>
        </div>
        <!--  <div class="field">
                  <label for="tema" class="label" >Nombre Emisora</label>
                  <input v-model="form.name" class="input width-full" type="text" placeholder="Nombre" />
                   <label for="tema" class="label" style="margin-top: 15px">Url a Redireccionar</label>
                  <input v-model="form.urlMenu" class="input width-full" type="text" placeholder="Url" />
        </div>-->
      </div>

      <!-- <div class="field">
        <label for="tema" class="label">Url Emisora</label>
        <input v-model="form.icon" class="input width-full" type="text" placeholder="Url" />
      </div>-->
      <div class="field" style="margin-top: 20px">
        <a v-if="!edit" class="button width-full" @click="add">Guardar</a>
        <a v-if="edit" class="button width-full" @click="update(form.id)">Guardar</a>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import HelloWorld from "@/components/HelloWorld.vue";
import myUpload from "vue-image-crop-upload";
import { setTimeout } from "timers";
import { db, storageRef } from "../services/firebase-init";
import { Carousel, Slide } from "vue-carousel";



export default {
  name: "crud",
  components: {
    HelloWorld,
    myUpload,
    Carousel,
    Slide
  },
  data() {
    return {
      imgDataUrl: "", // the datebase64 url of created image
      view: "list",
      edit: false,
      show: false,
          loading:false,
      list: [],
      url: '',
      listCopy:[],
      form: {},
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      }
    };
  },
   
  created() {
    let form = db.ref("/form/banners/homeTop");
    form.on("value", snapshop => {
      let obj = snapshop.val();
      let keys = Object.keys(obj);
      this.list = keys.map(key => {
        let result = { id: key, image: obj[key] };
        return result;
      });
      console.log(obj, "_____ ", this.list);
    });
  },
  updated() {
    // Fired every second, should always be true
 
    let obj = {}
     this.list.forEach((element, index) => {
         
        obj[(index+1).toString()] = element.image
      });
      let ref = db.ref("/form/banners/homeTop");
      ref.set(obj);
      console.log('^^^^' , obj)
  },
  methods: {
    setForm(id) {
      this.edit = id;
      this.view = "form";
      console.log(this.form, " .----- ", id);

      if (!this.edit) {
        this.imgDataUrl = "";
        this.form = {};
        return;
      }
      let ref = db.ref("/form/banners/homeTop");
      ref.child(id).on("value", snapshot => {
        let image = snapshot.val();
        this.form["image"] = image['image'] || '';
        this.form["url"] = image['url'] || '';
        this.form["id"] = id;
        console.log("````", this.form);
        this.imgDataUrl = this.form["image"];
        this.url = this.form["image"];
      });
    },

      launchFilePicker(){
        this.$refs.file.click();
      },
    checkMove(){
    
    },
    setList() {
      this.view = "list";
    },
    add() {
      let ref = db.ref("/form/banners/homeTop");
      let stations = ref.push();
      stations.set(this.form);
      this.setList();
    },
    update(id) {
      var obj = {};
      obj[id] = this.form;
      console.log("obj -", obj);
      let ref = db.ref("/form/banners/homeTop");

      ref.update(obj);
      this.setList();
    },
    remove(id) {
      if (!confirm("Realmente Desea eliminar el elemento")) return;
      let ref = db.ref("/form/banners/homeTop");
      ref.child(id).remove();
    },
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      var re = storageRef.child(String(Date.now()));
      re.putString(imgDataUrl, "data_url").then(() => {
        re.getDownloadURL().then(downloadURL => {
          this.form["image"] = downloadURL;
          this.$refs.uploader.setStep(2)
        });
        // console.log("-------- crop success --------", imgDataUrl);
      });
      this.imgDataUrl = imgDataUrl;
      
    },
        uploadImage(e){
          this.loading = true
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    console.log(this.previewImage);

                    var re = storageRef.child(String(Date.now()));
      re.putString(this.previewImage, "data_url").then(() => {
        re.getDownloadURL().then(downloadURL => {
          this.form["image"] = downloadURL;
        
                this.imgDataUrl =downloadURL;
                   this.loading = false
      //    this.$refs.uploader.setStep(2)
        });
        // console.log("-------- crop success --------", imgDataUrl);
      });
 
                };
            },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
         this.$refs.uploader.setStep(2)
    },
    base64toBlob(b64Data, sliceSize) {
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: "image/jpeg" });
      return blob;
    }
  }
};
</script>
<style scoped>
.content-form {
  width: 50vw;
}
.img-slide{
  max-width: 25%;
}

</style>