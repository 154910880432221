<!--eslint-disable-->
<template>
  <div style=" width:40vw; margin:0 auto;" class="content-form">
    <!-- <div class="field">
              <label for="tema" class="label" >Titulo</label>
              <input v-model="form.name" class="input width-full" type="text" placeholder="Nombre" />
            </div>

    -->

    <div class="field">
      <label for="message">Mensaje</label>
      <textarea
        id="message"
        rows="20"
        class="input width-full"
        style="width:1000px; height:200px"
        v-model="message"
      ></textarea>
    </div>
    <div class="field">
      <a class="button width-full" @click="enviar()">Enviar</a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import HelloWorld from "@/components/HelloWorld.vue";
import myUpload from "vue-image-crop-upload";
import { setTimeout } from "timers";
import { db, storageRef } from "../../services/firebase-init";
import axios from "axios";

export default {
  name: "crud",
  components: {
    HelloWorld,
    myUpload
  },
  data() {
    return {
      imgDataUrl: "", // the datebase64 url of created image
      view: "list",
      message: "",
      edit: false,
      show: false,
      list: [],
      form: {},
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      }
    };
  },
  
  methods: {
    enviar() {
      console.log("----- ");
      const data = {
        app_id: process.env.VUE_APP_ONESIGNAL_APP_ID,
        contents: { en: message.value },
        included_segments: "Active Users"
      };

      const options = {
        headers: {
          authorization: "Basic " + process.env.VUE_APP_REST_API_KEY,
          "Content-Type": "application/json"
        }
      };
      let currentObj = this;
      axios
        .post("https://onesignal.com/api/v1/notifications", data, options)
        .then(function(response) {
          currentObj.output = response.data;
        })
        .catch(function(error) {
          currentObj.output = error;
        });
    },

    base64toBlob(b64Data, sliceSize) {
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: "image/jpeg" });
      return blob;
    }
  }
};
</script>

	<style type="text/css">
</style>