import Firebase from 'firebase'

let config = {
  apiKey: 'AIzaSyAepb9rApE6AamHeNJWgdEcXkX1fPZt3H4',
  authDomain: 'mad-radio-f4131.firebaseapp.com',
  databaseURL: 'https://mad-radio-f4131.firebaseio.com',
  projectId: 'mad-radio-f4131',
  storageBucket: 'mad-radio-f4131.appspot.com',
  messagingSenderId: '636668632143',
  appId: '1:636668632143:web:adf4911be89249f1'
}
let app = Firebase.initializeApp(config)
let db = app.database()
let auth = app.auth()
let storageRef = app.storage().ref()
export { db, storageRef, auth }
