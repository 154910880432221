<!--eslint-disable-->
<template >
  <div id="login">
    <div>
      <h1 id="loginh1">
        <a></a>
      </h1>
      <p v-show="error" id="backtoblog">
        <a>Email o contraseña incorrecto</a>
      </p>
      <form name="loginform" id="loginform" action="#" @submit.prevent="submit">
        <p>
          <label>Nombre de usuario o correo electrónico</label>
          <input
            id="email"
            type="email"
            name="log"
            class="input"
            v-model="form.email"
            value
            size="20"
            autocapitalize="off"
          />
        </p>
        <div id="user-wrap">
          <label for="user_pass">Contraseña</label>
          <div class="wp-pwd">
            <input
              id="password"
              type="password"
              name="password"
              class="input password-input"
              v-model="form.password"
              value
              size="20"
            />
          </div>
        </div>
     <br />
  
        <p class="submit">
          <button @click="setForm()" class="button button-primary button-large">Acceder</button>
          <input type="hidden" name="redirect_to" value="https://madradio.co" />
      
        </p>
      </form>

      <p >
        <a id="volver" href="https://madradio.co/">&larr; Volver a MadRadio</a>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import HelloWorld from "@/components/HelloWorld.vue";
import myUpload from "vue-image-crop-upload";
import { setTimeout } from "timers";
import axios from "axios";
import { db, storageRef, auth } from "../services/firebase-init";

export default {
  name: "login",
  components: {
    HelloWorld,
    myUpload
  },
  data() {
    return {
      imgDataUrl: "", // the datebase64 url of created image
      view: "list",
      message: "",
      edit: false,
      show: false,
      list: [],
      form: {
        email: "",
        password: ""
      },
      error: null,
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      }
    };
  },
  methods: {
    setForm() {      this.error = false;
      console.log(" .----- ", this.form.email);

      auth
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(data => {
              console.log(" .----- ", data);
           localStorage.setItem('user', JSON.stringify(data));
               this.$router.push('home') 

        })
        .catch(err => {
          
          this.error = true;
          console.log(" .----- ", err.message);
        });
    },
    enviar() {
      console.log("----- ");
      const data = {
        app_id: process.env.VUE_APP_ONESIGNAL_APP_ID,
        contents: { en: message.value },
        included_segments: "Active Users"
      };

      const options = {
        headers: {
          authorization: "Basic " + process.env.VUE_APP_REST_API_KEY,
          "Content-Type": "application/json"
        }
      };
      let currentObj = this;
      axios
        .post("https://onesignal.com/api/v1/notifications", data, options)
        .then(function(response) {
          currentObj.output = response.data;
        })
        .catch(function(error) {
          currentObj.output = error;
        });
    },

    base64toBlob(b64Data, sliceSize) {
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: "image/jpeg" });
      return blob;
    }
  }
};
</script>

	<style type="text/css">
#loginh1 {
  background-image: url(https://madradio.co/wp-content/uploads/2020/03/mad-logo-mobile.png);

  margin: 0 auto 25px;
  width: 150px;
  height: 100px;
}
@media (-webkit-min-device-pixel-ratio: 2) {
  .login #login h1 a {
    background-image: url(https://madradio.co/wp-content/uploads/2020/03/mad-logo-mobilex2.png);
  }
}
#login {
  padding: 100px 0px 10px;
  background: #161616 !important;
  height: 100%;
  align-self: center;
}

#user-wrap {
  padding-top: 15px;
}
#loginform {
  padding: 26px 500px 10px;

  align-content: center;
  box-shadow: none;
  background: transparent;
}
#wp-submit {
  width: 50%;
  margin-top: 15px;
  height: 40px;
  border: 0;
  border-radius: 3px;
}
#login input[type="text"],
input[type="search"],
input[type="radio"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="password"],
input[type="checkbox"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
#login select,
#login textarea {
  border: 0;
  box-shadow: none;
}
.login #login form .input,
.login #login form input[type="checkbox"],
.login #login input[type="text"] {
  background: #272727;
  border: 0;
  border-radius: 3px;
}
#volver {
  color: #dd625c !important;
  opacity: 0.6;
}
#loginform label {
  color: #fff;
  opacity: 0.6;
}
.login #login #backtoblog a:hover,
.login #login #nav a:hover,
.login #login h1 a:hover {
  color: #dd625c;
  opacity: 1;
}
.login #login #backtoblog a,
.login #login #nav a,
.login #login label {
  color: #fff;
  opacity: 0.6;
}
.wp-pwd {
  position: relative;
}
.login #login form .input,
.login #login input[type="text"] {
  border: none;
  color: #fff;
  height: 40px;
  line-height: 40px;
  outline: none;
  background: #272727;
  margin-bottom: 15px;
  font-size: 1rem;
  width: 100%;
  border-radius: 3px;
  padding: 0 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
</style>