import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import webPlayer from './views/webPlayer.vue'
import LoginPage from './views/LoginPage.vue'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    { path: '/login', component: LoginPage },
    { path: '*', redirect: '/' },
    {
      path: '/web-player',
      name: 'webPlayer',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: webPlayer
    }
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/web-player']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    return next('/login')
  }
  next()
})
export default router
