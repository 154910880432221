
<!--eslint-disable-->
<!--EMISORAS-->
<template>
  <div class="crud">
    <div v-if="view == 'list'">
      <div class="column is-one-quarter level-left">
        <a class="button" @click="setForm(null)">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <div class="columns is-centered">
        <div class="table-container is-centered">
          <table class="table">
            <tr>
              <th>Icono</th>
              <th>Nombre</th>
              <th>Acción</th>
            </tr>
              <draggable v-model="list"   :list="list" group="people" @start="drag=true" @end="drag=false" tag="tbody">
                <tr v-for="item in list" :key="item.id">
                  <td><img class="disk" :src="item.image" alt="" height="70" width="70"> </td>
                  <td>{{item.name}}</td>
                  <td>
                      <a class="button" style="margin-right: 15px" @click="setForm(item.id)">Editar</a>
                      <a class="button is-danger" @click="remove(item.id)">Eliminar</a>
                  </td>
                </tr>
              </draggable>
            <!-- Your table content -->
          </table>
        </div>
      </div>
    </div>

    <!-- Este el formulario para agregar o editar elementos -->
    <div v-if="view == 'form'">
      <div class="column is-one-quarter level-left">
        <a  style="font-size: 24px; color: black" @click="setList">
            <i class="fas fa-arrow-left"></i>
        </a>
      </div>
      <div class="columns is-centered">
            <div class="field is-half images">
              <label for class="label">Imagen Disco Emisora</label>
              <a class="btn" @click="toggleShow(1)">
                <my-upload
                  field="img"
                  ref="uploader"
                  @crop-success="cropSuccess"
                  @crop-upload-success="cropUploadSuccess"
                  @crop-upload-fail="cropUploadFail"
                  v-model="show1"
                  :width="800"
                  :height="800"
                  url="https://httpbin.org/post"
                  lang-type="es-MX"
                  :params="params"
                  :headers="headers"
                  img-format="png"
                ></my-upload>
                <img
                  style="border-radius: 50%"
                  height="150"
                  width="150"
                  :src="imgDataUrl || 'https://bulma.io/images/placeholders/256x256.png'"
                />
              </a>
            </div>
              <!--
            <div class="field is-half images">
            <label for class="label">Imagen de el canal (LIGHT)</label>
              <a class="btn" @click="toggleShow(2)">
                <my-upload
                  field="img"
                  @crop-success="cropSuccess2"
                  @crop-upload-success="cropUploadSuccess2"
                  @crop-upload-fail="cropUploadFail"
                  v-model="show2"
                  :width="410"
                  :height="103"
                  url="https://httpbin.org/post"
                  lang-type="es-MX"
                  :params="params"
                  :headers="headers"
                  img-format="png"
                ></my-upload>
                <img
                  style="background-color: #eaeaea"
                   height="1230"
                  width="309"
                  :src="imgDataUrl2 || 'https://via.placeholder.com/410x103'"
                />
              </a>

              <div>
                <label for class="label">Imagen de el canal (DARK)</label>
              <a class="btn" @click="toggleShow(3)">
                <my-upload
                  field="img"
                  @crop-success="cropSuccess3"
                  @crop-upload-success="cropUploadSuccess3"
                  @crop-upload-fail="cropUploadFail"
                  v-model="show3"
                  :width="410"
                  :height="103"
                  url="https://httpbin.org/post"
                  lang-type="es-MX"
                  :params="params"
                  :headers="headers"
                  img-format="png"
                ></my-upload>
                <img
                  style="background-color: #eaeaea"
                  height="1230"
                  width="309"
                  :src="imgDataUrl3 || 'https://via.placeholder.com/410x103'"
                />
              </a>
              </div>
            </div>
-->
          </div>

          <div style="width:50vw; margin: auto">
            <div class="field">
              <label for="tema" class="label" >Nombre Emisora</label>
              <input v-model="form.name" class="input width-full" type="text" placeholder="Nombre" />
            </div>
            <div class="field">
              <label for="tema" class="label">Url Emisora</label>
              <input v-model="form.url" class="input width-full" type="text" placeholder="Url" />
            </div>
            <div class="field">
              <label for="tema" class="label">Url Streaming</label>
              <input v-model="form.urlStreaming" class="input width-full" type="text" placeholder="Url" />
            </div>
            <div class="field">
                  <a v-if="!edit" class="button width-full" @click="add">Guardar </a>
                  <a v-if="edit" class="button width-full" @click="update(form.id)">Guardar </a>
            </div>
          </div>

       </div>
    </div>

</template>
<script>
/* eslint-disable */
import HelloWorld from "@/components/HelloWorld.vue";
import myUpload from "vue-image-crop-upload";
import { setTimeout } from "timers";
import {db, storageRef} from "../../services/firebase-init";
import { ToastProgrammatic as Toast } from 'buefy'
export default {
  name: "crud",
  components: {
    HelloWorld,
    myUpload,
  },
  data() {
    return {
      imgDataUrl: "", // the datebase64 url of created image
      imgDataUrl2: "", // the datebase64 url of created image
       imgDataUrl3: "",
      view: "list",
      edit: false,
      show1: false,
      show2: false,
      show3: false,
      list: [],
      form: {},
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      },
    };
  },
  created() {
        let form = db.ref("/form/stations");
        let styles = db.ref("/form/styles");
        styles.on("value", snapshop => {
          let obj = snapshop.val();
          this.theme = obj['theme']
           console.log('?????ddsdds????----.- ', this.theme)
        });

        form.on("value", snapshop => {
            let obj = snapshop.val();
            console.log('obj ',obj)
            let keys = Object.keys(obj)
            this.list = keys.map((key) => {obj[key]['id'] = key ; return obj[key];} )
        console.log("_____ ", this.list);
        });
  },
  mounted(){

  },
  updated() {
  
    
    let obj = {}
     this.list.forEach((element, index) => {

        obj[(index+1).toString()] = element
      });
      let ref = db.ref("/form/stations");

      ref.set(obj);
      console.log('^^^^' , obj)
  },
  methods: {
    setForm(id) {
      this.edit = id;
      this.view = "form";
      console.log('----- ', id)

       if(!this.edit){
           this.imgDataUrl = '';
           this.imgDataUrl2 = '';
           this.imgDataUrl3 = '';
           this.form = {}
           return
       }
       let ref = db.ref("/form/stations")
       ref.child(id).on('value',(snapshot) => {
           this.form = snapshot.val();
           console.log('this.form===>>> ',this.form)
           this.form['id'] = id;
           this.imgDataUrl = this.form['image']
           this.imgDataUrl2 = (this.form['image2'])? this.form['image2'] : '';
           this.imgDataUrl3 = (this.form['image3'])? this.form['image3'] : '';
       })
    },
    setList() {
      this.view = "list";
    },
    requiredFields(){
     return Toast.open({
                    duration: 5000,
                    message: `Debes llenar todos los campos`,
                    position: 'is-top',
                    type: 'is-danger'
                })
    },
    sucessMessage(message){
        return Toast.open({
                    duration: 5000,
                    message: message,
                    position: 'is-top',
                    type: 'is-success'
                })
    },

    add(){

        if(!this.validFields(this.form))
        return this.requiredFields()

         let ref = db.ref("/form/stations")
         let stations = ref.push()
         stations.set(this.form);
         this.setList();
         this.sucessMessage('Se Guardo Exitosamente')
    },
    update(id){
      console.log('this.form ',this.form)
        if(!this.validFields(this.form))
        return this.requiredFields()
        let ref = db.ref("/form/stations")
         ref.child(id).update(this.form);
         this.setList();
        this.sucessMessage('Se Guardo Exitosamente');
    },
    validFields(form){
      if(Object.keys(this.form).length < 1) return false
      let resp =  Object.keys(this.form).map((key)=>{
          if(this.form[key]) return true
          else return false
      } )
      return resp.every((item)=> item == true)
    },
    remove(id){
        if(!confirm('Realmente Desea eliminar el elemento')) return
        let ref = db.ref("/form/stations")
        ref.child(id).remove()
    },
     toggleShow(num) {
      if(num == 1)
      this.show1 = !this.show1;
      if(num == 2)
      this.show2 = !this.show2;
      if(num == 3)
      this.show3 = !this.show3;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
       console.log(this.$refs, 'antes this.$refs----->> ',typeof this.$refs)
      var re = storageRef.child(String(Date.now()))
      re.putString(imgDataUrl, 'data_url').then(()=> {
        re.getDownloadURL().then((downloadURL) => {
           this.form['image'] = downloadURL;
        
           
           
          
         
      });
     // console.log("-------- crop success --------", imgDataUrl);

  });
     console.log(this.$refs, 'despues de gurarda this.$refs----->> ',typeof this.$refs)
      this.imgDataUrl = imgDataUrl;
      setTimeout(()=> this.$refs.uploader.setStep(1), 3000)
      
    },
      cropSuccess2(imgDataUrl, field) {
      var re = storageRef.child(String(Date.now()))
      re.putString(imgDataUrl, 'data_url').then(()=> {
        re.getDownloadURL().then((downloadURL) => {
           console.log('∞∞∞∞∞∞∞∞∞∞∞ ',this.theme)
           if(!this.form[this.theme]) this.form[this.theme]= {}
           this.form[this.theme]['image2'] = downloadURL;
           console.log('∞∞∞∞∞∞∞∞∞∞∞ ',this.form)
      });
     // console.log("-------- crop success --------", imgDataUrl);

  });
      this.imgDataUrl2 = imgDataUrl;
       this.$refs.uploader.setStep(2)
    },
    cropSuccess3(imgDataUrl, field) {
      var re = storageRef.child(String(Date.now()))
      re.putString(imgDataUrl, 'data_url').then(()=> {
        re.getDownloadURL().then((downloadURL) => {
           this.form[this.theme]['image3'] = downloadURL;
      });
     // console.log("-------- crop success --------", imgDataUrl);

  });
      this.imgDataUrl3 = imgDataUrl;
       this.$refs.uploader.setStep(2)
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {

      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
      cropUploadSuccess2(jsonData, field) {

      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
      cropUploadSuccess3(jsonData, field) {

      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
    base64toBlob (b64Data, sliceSize) {
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: "image/jpeg"});
    return blob;
  }

  }
};
</script>

<style scoped>
  .disk{
    border-radius: 50%;
  }
  .images{
    margin: 0 80px
  }
</style>
