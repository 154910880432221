<template>
  <div class="web-player">
    <div class="banner top">
      <Carousel :per-page="1" :mouse-drag="false" :paginationEnabled="false" :autoplayTimeout="10000" :autoplay="true">
        <slide v-for="(slide, index) in bannersHomeTop" :key="index">
          <img :src="slide['image']" alt />
        </slide>
      </Carousel>
    </div>
    <div class="list-stations">
      <Carousel
        :per-page="1"
        :paginationEnabled="false"
        :centerMode="true"
        :spacePadding="spaceWidth"
        @page-change="handleSlideClick"
      >
        <Slide
          :adjustableHeight="true"
          :loop="true"
          v-for="(slide, index) in stations"
          :key="index"
          style="width:100px"
        >
         <div>
              <Strong
                v-if="currentIndex == index && nowplaying['nowplaying']"
                class="title"
              >{{shorString(nowplaying['nowplaying'].split("-")[1] || '',18)}}</Strong>
              <Strong
                v-if="currentIndex == index && nowplaying['nowplaying']"
                class="title2"
              >{{shorString(nowplaying['nowplaying'].split("-")[0] || '',18)}}</Strong>
          </div>
          <div class="stations">
            <img
              class="disk imgr"
              :style="{'animation-play-state': plays[index]['play'] ? 'running':'paused'}"
              :class="{ focusImg: currentIndex == index }"
              :src="slide.image"
              alt
            />

            <div class="circle-white" @click="playSong(slide,index)">
              <img v-if="!plays[index]['play']" src="../assets/play-button.svg" alt class="play" />
              <img v-if="plays[index]['play']" class="pause" src="../assets/pause.svg" />
            </div>
          </div>
          <div class="footer-station" v-if="currentIndex == index">
            <!-- <img height="100" width="300" :src="slide.image2" alt /> -->
             <div class="content-canalName">
                        <span class="mad-text" style="color: white;">{{getCanalName(slide,0)}}</span> <span class="live"  v-if="getCanalName(slide,1)"  style="color: white;"> {{getCanalName(slide,1)}} </span>
              </div>
          </div>
        </Slide>
      </Carousel>
       <div class="footer-station">
        <!-- <p class="swipe">SWIPE TO CHOOSE A STATION / DESLIZA PARA CAMBIAR DE EMISORA</p> -->
      </div>
    </div>
    <div class="banner bottom">
      <Carousel :per-page="1" :mouse-drag="true" :paginationEnabled="false" :autoplayTimeout="12000" :autoplay="true">
        <Slide
          style="display: flex; align-items: flex-end; margin-top: -7px;"
          v-for="(slide, index) in bannersHomeBottom"
          :key="index"
        >
          <img :src="slide['image']" alt />
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import { Carousel, Slide } from "vue-carousel";
import { db, storageRef } from "../services/firebase-init";

export default {
  name: "webPlayer",
  components: {
    Carousel,
    Slide
  },

  data() {
    return {
      bannersHomeTop: {},
      bannersHomeBottom: {},
      stations: {},
      form: {},
      plays: [],
      audio: new Audio(),
      currentIndex: 0,
      spaceWidth: this.getWidth(),
      nowplaying: ""
    };
  },
  mounted() {
    // Note: do not add parentheses () for this.handleScroll
    window.addEventListener("resize", this.getWidth);
  },
  created() {
    // setTimeout(() => this.getWidth,1000)
    let stations = db.ref("/form");
    stations.once("value", snapshop => {
      this.form = snapshop.val();

      this.bannersHomeTop = this.getArrayFromObject(
        this.form["banners"]["homeTop"]
      );
      console.log('---------->>>> ',this.bannersHomeTop)
      this.bannersHomeBottom = this.getArrayFromObject(
        this.form["banners"]["homeBottom"]
      );
      this.stations = this.getArrayFromObject(this.form["stations"]);
      setInterval(
        () =>
          this.getNowPlaying(
            this.stations[this.currentIndex]["urlStreaming"]
          ),
        5000
      );
      this.plays = this.stations.map(item => {
        let play = { play: false, audio: null };
        return play;
      });
      this.setAudio("https://s2.radio.co/s953724e99/listen");
      console.log(
        "this.form: ",
        this.form,
        "this.stations : ",
        this.stations,
        "this.bannersTop ",
        this.bannersHomeTop,
        " this.bannerBottom ",
        this.bannersHomeBottom
      );
    });
  },
  methods: {
    getCanalName(item,index){
  let name = item.name
      if(!name) return ''

      let arrName = name.split(' ')
      if(arrName[index]) return arrName[index]
      else return ''
},
    setAudio(url = "") {
      this.audio.src = url;
    },
     getNowPlaying(url){

    console.log('lkdfhsdlkjfsdj ',url)
    if(url.includes('radioboss')){
      this.getNameRaddioboss(url)
      //console.log('el metodo incluye radioboss ', url)
    }
    else if(url.includes('radio.co')){
      let id = url.split('/')
      this.getNameRadioCo(id[4]);
    }
  },
  getNameRaddioboss(url) {
    console.log('el metodo incluye radioboss ', url)
    fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((myJson) => {
      this.nowplaying = myJson
      //console.log('this.nowplaying : ',this.nowplaying)
  });
  },
  getNameRadioCo(id){
  
    fetch(`https://public.radio.co/stations/${id}/status`)
    .then((response) => {
      return response.json();
    })
    .then((myJson) => {
      //console.log('la respuesta' ,myJson['current_track']['title'])
      this.nowplaying = {
        nowplaying: myJson['current_track']['title']
      }
      //console.log('this.nowplaying radioco : ',this.nowplaying)
  });
  },
    shorString(string='', number){ let end = (string.length > number) ? '...': ''  ;return `${string.slice(0, number)+ end}` },
    getWidth(event) {
      let windowWidth = document.documentElement.clientWidth;
      if (windowWidth > 910) this.spaceWidth = 300;
      else if (windowWidth < 910 && windowWidth > 610) this.spaceWidth = 180;
      else if (windowWidth < 610 && windowWidth > 410) this.spaceWidth = 100;
      else this.spaceWidth = 80;

      return this.spaceWidth;
      console.log(windowWidth, " resize pantalla ", this.spaceWidth);
    },
    /*getIdUrl(url = []) {
      console.log(this.currentIndex)
      let arrayUrl = url.split("=");
      let id = arrayUrl[arrayUrl.length - 1];
      return id;
    },*/
    //cambia el audio al cambiar el slide
    handleSlideClick(index) {
      this.resetPlays();
      if(this.plays && this.plays[index] && this.plays[index]["play"]) this.plays[index]["play"] = true;
      this.setAudio(this.stations[index].url);
      this.audio.play();
      this.currentIndex = index;
      
      this.getNowPlaying(
            this.stations[this.currentIndex]["urlStreaming"]
          )
    },
    //obtiene un array de un objeto, return ['1','2'] de { {uno : 1} ,{dos : 2} }
    getArrayFromObject(obj = {}) {
      return Object.keys(obj).map(key => {
        return obj[key];
      });
    },
    playSong(song, index) {
      console.log(index);
      this.plays[index]["play"] = !this.plays[index]["play"];

      if (!song.url) return;
      console.log(this.plays[index]["play"]);

      if (this.plays[index]["play"]) {
        this.audio.play();
      } else {
        console.log("removiendo");
        this.audio.pause();
      }
    },
    resetPlays() {
      this.plays = this.plays.map(item => {
        item.play = false;
        return item;
      });
    }
  }
};
</script>
<style scoped>
.width-full {
  width: 256px;
}
.banner img {
  height: 70%;
  width: 100%;
}
.list-stations {
  width: auto;
  background-color: black;
  margin-top: -7vh;
}
.web-player {
  width: 100vw;
  margin: 0 auto;
  background-color: black;
}
.disk {
  border-radius: 50%;
  height: 25vw;
  width: 25vw;
}
.VueCarousel-inner {
  flex-basis: inherit !important;
}
.list-stations p {
  color: white;
}
.stations {
  padding: 80px 0px 20px 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.circle-white {
  cursor: pointer;
}
.circle-white {
  background-color: white;
  width: 80px;
  height: 80px;
  /* top: 27vh; */
  border-radius: 50%;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-self: center;
}
.footer-station {
  width: auto;
  padding: 0px ;
}
.footer-station img {
  width: 200px;
}
.footer-station p {
  font-size: 14px;
  font-weight: bold;
}
.VueCarousel-slide {
  background-color: black;

  /*   flex-basis: auto; */
}
.list-stations .VueCarousel-slide {
  transform: translateX(0%);
  overflow: visible;
  /* flex-basis: 450px; */
}
.play {
  margin-left: 10px;
  height: 50px;
  margin-top: 15px;
}
.pause {
  margin-left: 0px;
  height: 40px;
  margin-top: 20px;
}
.swipe {
  font-size: 14px;
}
.space {
}
.content-canalName{
  font-size: 30px;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.imgr {
  -webkit-animation: 3s rotate linear infinite;
  animation: 12s rotate linear infinite;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.live{
  background-color: #d05753;
    border-radius: 1px;
    color: white;
    padding: 2px 5px 0px 1px;
    margin-left: 7px;
}
.focusImg {
  height: 32vw;
  width: 32vw;
}
.title {
  position: absolute;
  color: white;
  z-index: 1000 !important;
  font-size: 25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.title2 {
  position: absolute;
  color: white;
  top: 4vh;
  z-index: 1000 !important;
  font-size: 18px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  
}
@media (max-width: 600px) {
  .circle-white {
    width: 50px;
    height: 50px;
  }
  .disk {
    height: 45vw;
    width: 45vw;
  }
  .focusImg {
    height: 55vw;
    width: 55vw;
  }
  .play {
    height: 20px;
  }
  .pause {
    height: 20px;
    margin-top: 16px;
  }
  .top {
    margin-bottom: 4vh;
  }
  .title {
    padding: 18px 0 0 0;
    font-size: 20px;
  }
  .title2 {
    padding: 12px 0 0 0;
    font-size: 14px;
  }
}
@media (max-width: 450px) {
  .footer-station p {
    font-size: 9px;
  }
}
</style>